import React from 'react';

const BillOfLadingNo = ({trackingID}) => {
    return (
    <div style={{paddingTop: "5px", paddingBottom: "5px"}}>
        <div style={{fontSize: "1rem", display: "flex", justifyContent: 'space-between'}}><strong>Mã vận đơn: </strong><div>{trackingID}</div></div>
    </div>
    )
}

export default BillOfLadingNo;