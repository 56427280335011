import React from 'react';

export default function Stepper ({title, datetime, is_done, isLastProgress, is_current, description}) {
    // const currentDate = new Date();
    return (
        <li className={`${isLastProgress? "last-progress": "step-progess-item"} ${is_done? "is-done " : "not-done"} ${is_current? "current" :""}`} 
            style={{display: "flex"}}    
        >
            <div className='timestamp'>{datetime || ''}</div>
            <div>
                <strong style={(is_current || is_done) ? {color:"rgb(65, 138, 132)"} : {color: "rgb(112,112,112)"}}>{title}</strong>
                <div className="item-detail" style={{color: 'rgba(0,0,0,0.5)'}}>{description || ""}</div>
            </div>
            
        </li>
    )
}