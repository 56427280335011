
// import React from 'react';
// import './assets/main.css';

// function App () {
    //     return (
        //         <div>
        //             {/* <Counter /> */}
        //             <Components />
        //         </div>
        //     )
        // }
        
        // export default App;
        
import React, { useState, useEffect } from 'react';

import Components  from './/components';
// import './App.css';

// const steps = ['Step 1', 'Step 2', 'Step 3', 'Step 4', 'Step 5'];

function App() {
    // const [orderId, setOrderId] = useState("");
    const [tracerData, setTracerData] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    // const [isReturning, setIsReturning] = useState(false);
//   const [activeStep, setActiveStep] = useState(0);

//   const handleStepClick = (stepIndex) => {
//     setActiveStep(stepIndex);
//   };
    useEffect(() => {
        const pathParts = window.location.pathname.split("/");
        const lastPathSegment = pathParts[pathParts.length - 1] || "123";
        const url = process.env.REACT_APP_BACKEND_URL
        console.log("url", url);
        console.log("lastPathSegment", lastPathSegment);
        // setOrderId(lastPathSegment);
        // Make an API request using orderId to fetch order data
        // Replace this with your actual API call logic
        fetch(`${url}/web/delivery_services/tracer/${lastPathSegment}/`)
        .then((response) => 
            {
                console.log("response", response)
                if (response.status !== 200) {
                    setIsLoading(false);
                    return null;
                };
                return response.json()
            }
        )
        .then((data) => {
            setIsLoading(false);
            setTracerData(data);
           
        })
        .catch((error) => console.error(error));
    }, []);


  return (
    isLoading ? (
        <div>Loading...</div>
    ):
    (tracerData ? 
    <div className="container wrapper"><Components tracerData={tracerData}/></div> 
    :<div>Mã vận đơn không đúng hoặc chưa tồn tại...</div>
    )
    );
}

export default App;
