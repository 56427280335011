import React from 'react';
import BillOfLadingNo from './/BillOfLadingNo';
import Stepper from './/Stepper';
import '../assets/style.css';

// const convertDatetime = (datetime) => {
//   const originalDate = new Date(datetime);
//   if (isNaN(originalDate)) {
//     return "Invalid datetime format";
//   }

//   const year = originalDate.getFullYear();
//   const month = String(originalDate.getMonth() + 1).padStart(2, "0");
//   const day = String(originalDate.getDate()).padStart(2, "0");
//   const hours = String(originalDate.getHours()).padStart(2, "0");
//   const minutes = String(originalDate.getMinutes()).padStart(2, "0");
//   const seconds = String(originalDate.getSeconds()).padStart(2, "0");

//   const commonFormat = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
//   return commonFormat
// }

const Components = ({tracerData}) =>  {

  // const orderFirst = tracerData.orders.find((order) => order.sort_order_id === 1);
  // const orderSecond = tracerData.orders.find((order) => order.sort_order_id === 2);
  // const orderReturnFirst = tracerData.orders.find((order) => order.sort_order_id === 3);
  // const orderReturnSecond = tracerData.orders.find((order) => order.sort_order_id === 4);
  
  // const isReturning = orderReturnFirst || orderReturnSecond;
  // const isReturningDone = tracerData.state === 'Returing Done' || (orderReturnSecond && orderReturnSecond.state === 'Done' && orderReturnSecond.payment_state === 'Done' );
  const isCancelOrder = tracerData.state === 'Cancel';
  // const isDone = tracerData.state === 'Done' || (orderSecond && orderSecond.state === 'Done' && orderSecond.payment_state === 'Done' );
  
  // const isReturningDrop = isReturningDone || (orderReturnSecond && (orderReturnSecond.state === 'First Confirmed' || orderReturnSecond.state === 'Done'));
  // const isReturingBeingTransported = !isDone && (isReturningDrop || (orderReturnFirst && orderReturnFirst.state === 'Done'));
  // const isReturningPickUp = isReturingBeingTransported || (orderReturnFirst && orderReturnFirst.state === 'First Confirmed');
  // const isAtPudoDrop = (orderSecond && (isDone || (orderSecond.state === 'First Confirmed' || orderSecond.state === 'Done'))) || isReturningPickUp;
  // const isBeingTransported = isAtPudoDrop || (orderFirst && orderFirst.state === 'Done');
  // const isAtPudoPickUp = isBeingTransported || orderFirst.state === 'First Confirmed';
  // const isDraft = true;

  // console.log("isDone", isDone);
  // // console.log("isReturningDrop", ((orderReturnSecond.state === 'First Confirmed' || orderSecond.state === 'Done')));
  // console.log("orderSecond", orderSecond);
  // console.log("isReturning", isReturning);
  // console.log("isReturningPickUp", isReturningPickUp);
  // console.log("isReturingBeingTransported", isReturingBeingTransported);
  // console.log("orderReturnSecond", orderReturnSecond);


  if (isCancelOrder){
    return <>
      <BillOfLadingNo trackingID ={tracerData.tracking_id}/>
      <div style={{"position": "relative", left: "10px"}}>Đơn hàng đã bị hủy.</div>
      </>
  }

  return (
    <>
      <BillOfLadingNo trackingID ={tracerData.tracking_id}/>
      <hr />
      {
        <div>
          <ul className="step-progess">
            {/* <Stepper 
                title={"Tạo yêu cầu gửi hàng"} 
                is_done={isDraft}
                is_current={tracerData.state ===  "Draft"}
                datetime={tracerData.created_at? convertDatetime(tracerData.created_at) : null}
                />
            <Stepper 
              title={`Đơn hàng đã được gửi tại trạm giao nhận ${orderFirst.dispatch_station_description}`} 
              is_done={isBeingTransported} 
              is_current={!isBeingTransported && isAtPudoPickUp}
              datetime={isAtPudoPickUp && orderFirst.start_time? convertDatetime(orderFirst.start_time) : null}
              />
            <Stepper 
              title={orderSecond ? `Đơn hàng đang được vận chuyển đến trạm giao nhận ${orderSecond.dispatch_station_description}` : 'Đơn hàng đang trong quá trình vận chuyển'}
              is_done={(isDone && !orderSecond) || isAtPudoDrop || isReturningPickUp}
              is_current={!((isDone && !orderSecond) || isAtPudoDrop || isReturningPickUp) && isBeingTransported}
              datetime={isBeingTransported && orderFirst.end_time? convertDatetime(orderFirst.end_time) : null}
              />
            {!isReturning && isAtPudoDrop ? 
            <Stepper 
              title={`Đơn hàng đã được gửi đến trạm giao nhận ${orderSecond.dispatch_station_description}`}
              is_done = {orderSecond && (isDone || orderSecond.state === 'Done')}
              is_current = {orderSecond && (orderSecond.state === 'First Confirmed')}
              datetime={isAtPudoDrop && orderSecond.start_time? convertDatetime(orderSecond.start_time) : null}
              />
              : <></>}
            {isReturning ? 
            <Stepper 
              title={`Đơn hàng đã được gửi đến trạm giao nhận ${orderSecond.dispatch_station_description}`}
              is_done = {isReturingBeingTransported}
              is_current={(orderReturnFirst && orderReturnFirst.state === 'First Confirmed')}
              datetime={orderSecond && orderSecond.start_time? convertDatetime(orderSecond.start_time) : null}
              />
              : <></>}
            {isReturning ? <Stepper 
              title={`Đơn hàng đang được vận chuyển hoàn trả về trạm giao nhận ${orderReturnSecond.dispatch_station_description}`}
              is_done = {isReturingBeingTransported}
              is_current = {!isReturningDrop && isReturingBeingTransported}
              datetime={orderReturnFirst && orderReturnFirst.end_time ? convertDatetime(orderReturnFirst.end_time ) : null}
              />
              : <></>}
            {isReturning ? <Stepper 
              title={`Đơn hàng đã được hoàn trả vào trạm giao nhận ${orderReturnSecond.dispatch_station_description}`}
              is_done = {orderReturnSecond && orderReturnSecond.state === 'Done'}
              is_current={orderReturnSecond && orderReturnSecond.state === 'First Confirmed'}
              datetime={isReturningDrop && orderReturnSecond.start_time ? convertDatetime(orderReturnSecond.start_time ) : null}
              />
              : <></>}
            {isReturning ? <Stepper 
              title={"Đơn hàng đã được hoàn trả thành công"}
              isLastProgress={true}
              is_done={isReturningDone}
              datetime={isReturningDone && tracerData.updated_at ? convertDatetime(orderSecond.updated_at ) : null}
              />
              : <></>}
            {!isReturning ? <Stepper 
              title={"Đơn hàng đã được giao thành công"}
              isLastProgress={true}
              is_done={isDone}
              datetime={isDone && tracerData.updated_at ? convertDatetime((orderSecond && orderSecond.updated_at) || tracerData.updated_at) : null}
              />
              : <></>} */}
          {tracerData.statuses_tracking.map((item, index) => (
            <Stepper 
            key={index}  
            title = {item.title}
            is_done = {item.is_done}
            is_current = {item.is_current}
            datetime = {item.datetime}
            isLastProgress = {item.is_last_progress}
            />
          ))}
          </ul>
        </div>}
    </>
    
  );
};

export default Components;

