import React from 'react';
import ReactDOM from 'react-dom/client';

import './assets/main.css'
import App from './App';
// import './dotenvSetup'; 

// import {store} from './config/store';
// import { Provider } from 'react-redux';

const el = document.getElementById('root');
const root = ReactDOM.createRoot(el);

root.render(
    <React.StrictMode>
        {/* <Provider store={store}> */}
        <App />
        {/* </Provider> */}
    </React.StrictMode>
);